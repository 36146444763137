<template>
  <v-container class="my-12">
    <v-row>
      <v-col cols="12">
        <h2 class="text-h4 mb-4">All the advantages of Web3 - without the fees ...</h2>
      </v-col>

      <!-- Advanced Hardware Wallet Integration -->
      <v-col cols="12" md="6" lg="4">
        <v-card class="pa-3" outlined>
          <v-card-title>
            <v-icon left size="48" class="color1 mr-2">mdi-wallet</v-icon>
            Advanced Hardware Wallet Integration
          </v-card-title>
          <v-card-text>
            Seamlessly integrates with hardware wallets featuring fingerprint sensors or leveraging iPhone's Face ID, supported by an additional NFC-enabled EAL7+ card.
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Universal Compatibility -->
      <v-col cols="12" md="6" lg="4">
        <v-card class="pa-3" outlined>
          <v-card-title>
            <v-icon left size="48"  class="color2 mr-2">mdi-earth</v-icon>
            Universal Compatibility
          </v-card-title>
          <v-card-text>
            Supports industry standards like Web3, WalletConnect 2.0, MetaMask, Ledger, and Tangem, ensuring flexibility and no vendor lock-in.
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Multi-Signature Administration -->
      <v-col cols="12" md="6" lg="4">
        <v-card class="pa-3" outlined>
          <v-card-title>
            <v-icon left size="48" class="color3 mr-2">mdi-account-multiple-check</v-icon>
            Multi-Signature Administration
          </v-card-title>
          <v-card-text>
            Implement multi-signature configurations for admin and signer roles, requiring multiple approvals for critical actions.
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Temporal Tracking of Permissions -->
      <v-col cols="12" md="6" lg="4">
        <v-card class="pa-3" outlined>
          <v-card-title>
            <v-icon left size="48" class="color3 mr-2">mdi-timeline-clock-outline</v-icon>
            Temporal Tracking of Permissions
          </v-card-title>
          <v-card-text>
            Ensures signatures are valid only if the signer had appropriate permissions at the time of signing, adding a layer of security and control.
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Blockchain with 100% Uptime -->
      <v-col cols="12" md="6" lg="4">
        <v-card class="pa-3" outlined>
          <v-card-title>
            <v-icon left size="48" class="color1 mr-2">mdi-check</v-icon>
            Blockchain Reliability
          </v-card-title>
          <v-card-text>
            Utilize blockchain technology designed for 100% uptime, ensuring your security and authentication services are always available.
          </v-card-text>
        </v-card>
      </v-col>

      <!-- End-to-End Encryption -->
      <v-col cols="12" md="6" lg="4">
        <v-card class="pa-3" outlined>
          <v-card-title>
            <v-icon left size="48" class="color3 mr-2">mdi-lock</v-icon>
            End-to-End Encryption
          </v-card-title>
          <v-card-text>
            Protect your data with end-to-end encryption throughout the signing process, from data input to backend verification.
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Easy Integration and Scalability -->
      <v-col cols="12" md="6" lg="4">
        <v-card class="pa-3" outlined>
          <v-card-title>
            <v-icon left size="48" class="color2 mr-2">mdi-puzzle</v-icon>
            Easy Integration and Scalability
          </v-card-title>
          <v-card-text>
            With straightforward SDKs and APIs, integrating Input Lock into your systems is hassle-free, designed to scale with your needs.
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Comprehensive Audit Trails -->
      <v-col cols="12" md="6" lg="4">
        <v-card class="pa-3" outlined>
          <v-card-title>
            <v-icon left size="48" class="color3 mr-2">mdi-file-document-outline</v-icon>
            Comprehensive Audit Trails
          </v-card-title>
          <v-card-text>
            Maintain full visibility with comprehensive audit trails, tracking who signed what and when for accountability and compliance.
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Customizable Smart Contracts -->
      <v-col cols="12" md="6" lg="4">
        <v-card class="pa-3" outlined>
          <v-card-title>
            <v-icon left size="48" class="color1 mr-2">mdi-script-text</v-icon>
            Customizable Smart Contracts
          </v-card-title>
          <v-card-text>
            Tailor smart contracts to fit your organizational needs, defining specific rules for data handling and authorization frameworks.
          </v-card-text>
        </v-card>
      </v-col>

      <!-- User-Friendly Experience -->
      <v-col cols="12" md="6" lg="4">
        <v-card class="pa-3" outlined>
          <v-card-title>
            <v-icon left size="48" class="color2 mr-2">mdi-face-agent</v-icon>
            User-Friendly Experience
          </v-card-title>
          <v-card-text>
            Despite advanced security features, Input Lock is designed for ease of use, making secure transactions accessible to everyone.
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FeaturesPage',
};
</script>

<style>
/* Add custom styles here */
</style>
