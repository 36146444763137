import { createApp } from 'vue'
import App from './App.vue'
import router from './router'; // Adjust the path as necessary
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'


// MDI Icons
import '@mdi/font/css/materialdesignicons.css';

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#0db5ee', // Custom primary color
        },
      }
    },
  }
})
createApp(App)
  .use(router)
  .use(vuetify)
  .mount('#app')
