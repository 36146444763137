<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8" style="max-width: 900px; min-width: 900px;">

        <v-card-title class="text-h5">
          About Us
        </v-card-title>
        <v-card-text>
          <p>
            We are a Decentralized Autonomous Organization of security and Web3 enthusiasts, dedicating our time to provide the highest quality tools for the modern IT environment. Our passion for innovation and commitment to enhancing online security drives us to develop solutions that empower organizations and individuals to protect their digital assets effectively.
          </p>
          <p>
            To underline our non-profit philosophy and to comply fully with international law, we are legally an association based in Winterthur, Switzerland.
          </p>

          <v-btn color="primary" class="my-10 button-text-no-caps" size="large" variant="flat" target="_blank" href="https://app.aragon.org/#/daos/polygon/0xe3a0701516a4bf58c6a0c50fdea19019dc4a81fc/dashboard">
            <v-icon icon="mdi-account-group" class="mr-5"/>
            Visit our DAO!
          </v-btn>

          <div class="mt-12 mb-8">
            <v-card-title class="font-weight-bold text-h5 mb-3">Statutes of the Inputlock DAO</v-card-title>
            <v-card outlined class="mb-6" v-for="(article, index) in articles" :key="index">
              <v-card-title>Article {{ index + 1 }}: {{ article.title }}</v-card-title>
              <v-card-text class="ml-4" align="left">
                <div v-html="article.content"></div>
              </v-card-text>
            </v-card>

          </div>

          <div class="my-5">
            <v-card-title>Founding Members</v-card-title>
            <v-card-text>
              <p>The organization was officially established as a Swiss 'Verein' (association) in Winterthur, Switzerland, on March 3, 2024, by the following founding members:</p>
              <v-row align="center" class="my-10">
                <v-col v-for="(member, index) in boardMembers" :key="index" cols="12" md="4">
                  <v-card>
                    <v-card-title>{{ member.name }}</v-card-title>
                    <v-card-subtitle>{{ member.location }}</v-card-subtitle>
                    <v-card-subtitle v-if="member.role">{{ member.role }}</v-card-subtitle>

                    <v-tooltip location="bottom">
                      <template v-slot:activator="{ props }">
                        <v-card-subtitle @click="copyToClipboard(member.address)" v-bind="props" dense v-if="member.address">{{ member.address }}</v-card-subtitle>
                      </template>
                      Click to copy to clipboard.
                    </v-tooltip>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </v-card-text>

      </v-col>
    </v-row>
    <v-snackbar
        v-model="snackbar"
        :timeout="2000"
        top
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: 'AboutUsPage',
  methods: {
    copyToClipboard (text) {
      if (navigator.clipboard && window.isSecureContext) {
        // Use the Clipboard API
        navigator.clipboard.writeText(text).then(() => {
          this.snackbarText = text +' copied to clipboard!';
          this.snackbar = true;
        }, (err) => {
          console.error('Could not copy text: ', err);
          this.snackbarText = 'Failed to copy address.';
          this.snackbar = true;
        });
      } else {
        // Fallback for older browsers
        let textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          this.snackbarText = 'Address copied to clipboard!';
          this.snackbar = true;
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
          this.snackbarText = 'Failed to copy address.';
          this.snackbar = true;
        }
        document.body.removeChild(textArea);
      }
    }
  },
  data: () => ({
    snackbar: false,
    snackbarText: '',
    boardMembers: [
      { name: 'Alex Stämpfli', location: 'Winterthur, CH', role: 'Co-Founder', address: '0xba5E60dCd8462e1855f416E03A6857c0C21A7F0c' },
      { name: 'Martin Schwaller', location: 'Winterthur, CH', role: 'Founder', address: '0xE44Dbb744f1Cd0B81198604AE514abC4BBbB88D6' },
      { name: 'Sandy Zeh', location: 'Winterthur, CH', role: 'Co-Founder', address: '0x89E25c415B2A29ac1BdBfaA365F86B6F97a807b1' }
    ],
    articles: [
      {
        title: 'Name and Location',
        content: 'The association is named "Inputlock DAO" and is based in Winterthur, ZH, Switzerland.'
      },
      {
        title: 'Purpose',
        content: '"Inputlock DAO” aims to enhance security and privacy in the digital realm, focusing on the development and provision of technologies and solutions for secure data entry and management. Operating on a Decentralised Autonomous Organization (DAO) model, the association does not pursue commercial purposes and seeks no profit.'
      },
      {
        title: 'Membership',
        content: 'Membership is open to any natural or legal person holding the governance token TEGRITY. The conditions for acquiring, holding, and transferring these tokens, along with associated voting rights and obligations, are detailed in a separate document ("Token Governance Policy"), approved and published by the board.'
      },
      {
        title: 'Association Bodies',
        content: 'The association\'s bodies include the General Assembly and the Board. The General Assembly is conducted virtually, utilising blockchain technology for organisation and execution.'
      },
      {
        title: 'Board',
        content: 'The board consists of at least three DAO members, elected through an on-chain voting process for a term of two years. The board is responsible for strategic direction and management of the association.'
      },
      {
        title: 'General Assembly',
        content: 'The ordinary General Assembly is held annually as an on-chain event. All members can participate in voting via blockchain. Extraordinary General Assemblies can be convened through an on-chain voting process.'
      },
      {
        title: 'Dissolution of the Association',
        content: 'The association can only be dissolved through an on-chain vote with a two-thirds majority of the votes cast.'
      },
      {
        title: 'Final Provisions',
        content: 'The statutes and the Token Governance Policy can be amended by the General Assembly through an on-chain voting process. Amendments requirements are defined and documented on the DAO online platform like any other vote.'
      },
      {
        title: 'Founding Protocol and Member List',
        content: 'Given that membership is based on the ownership of governance tokens, the traditional member list is replaced by a dynamic list of current token holders, changing with the buying and selling of tokens. The founding protocol would document the creation and implementation of the token\'s smart contract and the initial distribution of tokens.'
      },
      {
        title: 'Token Governance Policy',
        content: 'A separate document, the "Token Governance Policy," would detail the mechanisms of the token economy, rights and obligations of token holders, conditions for participation in votes, and rules for token distribution and transfer.'
      },
      {
        title: 'Financial Provisions',
        content: 'For transparency all assets will be managed by the DAO’s smart contract based treasury and no traditional bank account shall be used.<br>' +
                 'Liability Clause: The liability of the association is limited exclusively to its assets. Members and the board of directors shall not be personally liable for the association\'s debts or other financial obligations.'
      },
      {
        title: 'Receipt of Donations',
        content: '<ul>\n' +
                 '  <li>The Association is authorised to receive donations in various forms, including but not limited to cryptocurrencies, and digital assets, as long as those are received by the DAO’s treasury wallet. These donations may be used to fund the Association\'s operations, projects, and activities in alignment with its mission.</li>\n' +
                 '  <li>Donations received by the Association are subject to the regulations and conditions set forth by the Association\'s governing body and must comply with applicable laws and regulations.</li>\n' +
                 '  <li>The Association commits to utilising donations efficiently and transparently, ensuring they contribute to the advancement of the Association\'s objectives.</li>\n' +
                 '</ul>'
      },
      {
        title: 'Token Buyback for Future Development',
        content: '<ul>\n' +
                 '  <li>The Association reserves the right to buy back its governance tokens from the market using its funds. This action is aimed at supporting the Association\'s long-term development and sustainability.</li>\n' +
                 '  <li>The conditions, timing, and volume of such buybacks will be determined by the Association\'s governing body in accordance with the Association\'s strategic needs and in compliance with relevant regulations.</li>\n' +
                 '  <li>Token buybacks will be conducted transparently, and details will be disclosed to the Association\'s members and the broader community as deemed appropriate by the governing body.</li>\n' +
                 '  <li>The Association may create a dedicated fund for the purpose of token buyback, which will be managed according to the principles of transparency, accountability, and in the best interest of the Association\'s mission.</li>\n' +
                 '</ul>'
      },
      {
        title: 'Use of Funds',
        content: '<ul>\n' +
                 '  <li>Funds received by the Association, whether from donations or other sources, will be used in accordance with the Association\'s objectives as outlined in these statutes.</li>\n' +
                 '  <li>The Association shall maintain accurate and transparent records of all financial transactions, including the receipt of donations and expenditures related to token buybacks and other activities.</li>\n' +
                 '  <li>The Association is committed to financial prudence and shall allocate funds in a manner that ensures the long-term sustainability and growth of the DAO and its underlying technology and community.</li>\n' +
                 '</ul>'
      }
    ]
  }),
};
</script>

<style>
/* Add custom styles here */
</style>
