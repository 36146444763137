<template>
  <v-container class="mt-4 mb-12">
    <v-row>
      <v-col>
        <h2 class="text-h4">How It Works</h2>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <div class="mb-4"  style="width: 800px; height: 600px;">
          <v-img :src="overviewDiagramSrc" contain width="800"/>
        </div>
      </v-col>
      <v-row justify="center">
        <v-col cols="auto">
          <v-card class="pa-5 mb-5" outlined style="max-width: 450px">
            <h3 class="title">For Organizations</h3>
            <v-list dense>
              <v-list-item align="left">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-code-tags</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Integrate our frontend and backend libraries for message signing and verification.</v-list-item-content>
              </v-list-item>
              <v-list-item align="left">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Manage trust using smart contracts, allowing for multi-admin configurations.</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="auto">
          <v-card class="pa-5 mb-5" outlined style="max-width: 450px">
            <h3 class="title">For Users</h3>
            <v-list dense>
              <v-list-item align="left">
                <v-list-item-icon>
                  <v-icon color="" class="mr-3">mdi-numeric-1-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Input your data in your form.</v-list-item-content>
              </v-list-item>
              <v-list-item align="left">
                <v-list-item-icon>
                  <v-icon color="" class="mr-3">mdi-numeric-2-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Press the "Store" button to initiate a signature request from your web3 wallet, securely displaying the form content.</v-list-item-content>
              </v-list-item>
              <v-list-item align="left">
                <v-list-item-icon>
                  <v-icon color="" class="mr-3">mdi-numeric-3-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Upon user confirmation to sign, the data is securely transferred to the application's backend for signature validation, confirming your affiliation with the organization.</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="auto">
          <v-card class="pa-5 mb-5" outlined style="max-width: 450px">
            <h3 class="title">For Software Companies</h3>
            <v-list dense>
              <v-list-item align="left">
                <v-list-item-content>Integrate our frontend and backend libraries for message signing and verification to enhance your application's security.</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
  </v-container>


</template>

<script>
export default {
  data () {
    return {
      overviewDiagramSrc: require('@/assets/overview.png')
    };
  }
};
</script>