<template>
  <v-container style="max-width: 850px">
    <v-row  class="my-12">
      <v-col cols="12">
        <h2 class="text-h4 mb-4">The Governance Token</h2>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6" class="text-center">
            <v-img :src="iconSrc"
                class="my-5"
                contain
                height="256"
            />
          </v-col>
        </v-row>
        <h1 class="color1 mb-4">TEGRITY</h1>
        <h8>Release Date: March 12 2024</h8>
      </v-col>

      <!-- Introduction and Launch Information -->
      <v-row>
        <v-col outlined class="mt-6 mb-10">
          <v-card-text>
            To govern our project as a nonprofit DAO, we introduce the project governance token
            <span class="color1">TEGRITY</span> - a reminder that integrity is paramount. We're committed to launching it as fairly and transparent as possible. Please have a look at the initial distribution below.
          </v-card-text>
        </v-col>
      </v-row>

      <!-- Transaction Fee Information -->
      <v-row justify="center">
        <v-col cols="12" md="6"> <!-- Adjust the column size as needed -->
          <v-card outlined class="mb-6">
            <v-card-title class="font-weight-bold">Facts</v-card-title>
            <v-card-text class="font-weight-bold">
              <table>
                <tr>
                  <td>Name</td>
                  <td>Inputlock Coin</td>
                </tr>
                <tr>
                  <td>Symbol</td>
                  <td>TEGRITY</td>
                </tr>
                <tr>
                  <td>Max. supply</td>
                  <td>100'000'000</td>
                </tr>
                <tr>
                  <td>Updatable</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>Contract Address&nbsp;&nbsp;&nbsp;</td>
                  <td>0x0000000000000000000</td>
                </tr>
                <tr>
                  <td>Transaction Tax</td>
                  <td>0.5%</td>
                </tr>
                <tr>
                  <td></td>
                  <td><span><div class="font-weight-light">Adjustable by DAO: 0% - 3%</div></span></td>
                </tr>
              </table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-row>
          <v-col outlined class="mt-6 mb-10">
            <v-card-text>
              A transaction fee of 0.5% is applied to support the DAO, with a cap at 3% to accommodate changing market conditions and feature enhancements. This approach ensures everything remains within reasonable bounds, providing peace of mind to our supporters.
            </v-card-text>
          </v-col>
        </v-row>
      </v-row>

<!--      <v-row >-->
<!--        <v-col cols="12">-->
<!--          <h2 class="pt-8">Initial Distribution</h2>-->
<!--        </v-col>-->
<!--      </v-row>-->

      <v-row justify="center">
        <v-col cols="12" md="6"> <!-- This ensures the column takes the full width on small screens and half on medium screens and above -->
          <v-img :src="daoDistributionDiagramSrc" width="473" height="436"/>
        </v-col>
      </v-row>
<!--        <v-col  cols="6">-->
<!--          <v-img :src="initialDistributionDiagramSrc" width="458" height="444"/>-->
<!--        </v-col>-->



      <!-- DAO Reserves and Future Development -->
      <v-row>
        <v-col outlined class="mt-8 mb-6">
          <v-card-title class="font-weight-bold">DAO Reserves & Future Development</v-card-title>
          <v-card-text align="left">
            The DAO's reserves are dedicated to future development and the introduction of staking rewards. While we're open to the possibility of issuing staking rewards or
            burning part of the reserves, our primary focus is on leveraging Web3 technologies for real-life solutions that advance the IT industry. The majority of the reserves will be allocated to turning this vision into reality.
          </v-card-text>
        </v-col>
      </v-row>
    </v-row>

  </v-container>
</template>

<script>
export default {
  data () {
    return {
      iconSrc: require('@/assets/token.png'),
      iconLogoSrc: require('@/assets/logo.png'),
      daoDistributionDiagramSrc: require('@/assets/dao-founding-distribution.png'),
      initialDistributionDiagramSrc: require('@/assets/initial-distribution.png')
    };
  }
};
</script>

<style scoped>
/* CSS to remove the underline by default */
.link {
  color: rgb(var(--v-theme-primary));
  text-decoration: none;
}

/* CSS to add underline on hover */
.link:hover {
  text-decoration: underline;
}

.primary-color {
  color: rgb(var(--v-theme-primary));
}
</style>
