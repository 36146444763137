// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';

// Import component files
import DAOPage from '@/components/DAOPage.vue'
import HomePage from '@/components/HomePage.vue'
import FeaturesPage from '@/components/FeaturesPage.vue'
import HowItWorksPage from '@/components/HowItWorksPage.vue'
import SDKPage from '@/components/SDKPage.vue'
import TokenPage from '@/components/TokenPage.vue'

const routes = [
  { path: '/', redirect: '/home' },
  { path: '/home', name: 'home', component: HomePage },
  { path: '/features', name: 'features', component: FeaturesPage },
  { path: '/how', name: 'how', component: HowItWorksPage },
  { path: '/sdk', name: 'sdk', component: SDKPage },
  { path: '/token', name: 'token', component: TokenPage },
  { path: '/dao', name: 'dao', component: DAOPage },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;