<template>
  <v-container class="my-12">
    <v-row  justify="center">
      <v-col cols="12">
        <h2 class="text-h4 mb-4">Software & Libraries</h2>
      </v-col>

      <v-card-text style="max-width: 500px">

        <p>
          We provide libraries for Java and Java Script for a simple fullstack integration.
          You can find more information on GitHub :-)
        </p>
        <v-list>
          <v-list-item class="color1 mt-8" href="https://rest.inputlock.com/swagger-ui/index.html" target="_blank">
            <v-list-item-icon>
              <v-icon size="48" class="color1 mb-5">mdi-server</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <p class="text-green">OpenAPI Docs / Test System</p>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="color2 mt-8" href="https://github.com/inputlock" target="_blank">
            <v-list-item-icon>
              <v-icon class="color2" size="48">mdi-github</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <p>GitHub</p>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="color3 mt-8" href="https://hub.docker.com/repositories/inputlock" target="_blank">
            <v-list-item-icon>
              <v-icon class="color3" size="64">mdi-docker</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <p>Docker</p>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SDKPage',
};
</script>

<style scoped>
/* CSS to remove the underline by default */

</style>