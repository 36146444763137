<script setup>

</script>

<template>
  <v-container>
    <v-row align="center" justify="center" class="text-center">
      <h1 class="display-1">Secure Your Form Inputs with Blockchain Technology</h1>
    </v-row>
    <v-row align="center" justify="center" class="text-center">
      <v-img :src="iconSrc"
          class="mt-16 mb-5"
          contain
          width="512"
          height="512"
      />
    </v-row>
    <v-row align="center" justify="center" class="text-center">
      <v-btn href="https://dapp.inputlock.com/" target="_blank" color="" class="button-text-no-caps" variant="outlined">
        Launch Beta App!
      </v-btn>
    </v-row>

    <v-row class="mt-3" justify="center">

      <v-col cols="12" md="4" class="mt-0" style="max-width: 350px">
        <v-card class="pa-5" outlined>
          <v-icon class="color1" size="128">mdi-wallet</v-icon>
          <h2 class="color1">Web3 Wallets</h2>
          <p>Easily integrate with leading hardware-based wallets for maximum protection.</p>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="mt-10" style="max-width: 350px">
        <v-card class="pa-5" outlined>
          <v-icon size="128" class="color2">mdi-link</v-icon>
          <h2 class="color2">Seamless Integration</h2>
          <p>Quick setup with your web forms to start securing data in minutes.</p>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="mt-5" style="max-width: 350px">
        <v-card class="pa-5" outlined>
          <v-icon class="color3" size="128">mdi-lock</v-icon>
          <h2 class="color3">Enhanced Security</h2>
          <p>Utilize blockchain's immutable security to protect your data.</p>
        </v-card>
      </v-col>
    </v-row>


    <v-container class="my-12" style="max-width: 850px">
      <v-row align="center">
        <div style="max-width: 800px">
          <h1 class="pa-9" >Why?</h1>
          <p align="left">To appreciate the significance of digital signing, it's essential to delve into a series of scenarios that underscore its importance. These scenarios not only highlight potential risks but also demonstrate how being equipped with digital signing capabilities can prepare you for a variety of challenges. By understanding these examples, you'll gain insight into the critical role that digital signing plays in safeguarding your digital assets and communications. Let's explore these situations in detail to grasp why digital signing should be a key component of your security strategy.
          </p>  <div class="mx-auto" outlined>
            <v-card-title class="py-10">Security Scenarios</v-card-title>
            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="" style="border-bottom: 1px solid #E0E0E0; width: 34%;">Event</th>
                    <th class="" style="border-bottom: 1px solid #E0E0E0; width: 33%">Without Inputlock</th>
                    <th class="" style="border-bottom: 1px solid #E0E0E0; width: 33%">With Inputlock</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td style="text-align: left; padding: 16px; border-bottom: 1px solid #E0E0E0;">{{ item.event }}</td>
                    <td style="text-align: left; padding: 16px; border-bottom: 1px solid #E0E0E0;">{{ item.without }}</td>
                    <td style="text-align: left; padding: 16px; border-bottom: 1px solid #E0E0E0;">{{ item.with }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </div>
        </div>
      </v-row>
    </v-container>

    <v-row class="mt-16 pt-10" justify="center">
      <h3>
        Connect with us on various social platforms!
      </h3>
    </v-row>
    <v-row class="mt-10" justify="center">
      <v-row justify="center">
        <v-col cols="12">
          <v-list dense class="d-flex justify-center">

            <v-list-item class="d-flex">
              <v-list-item-icon>
                <v-icon class="color3">mdi-twitter</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <a class="link" href="https://twitter.com/inputlock176908" target="_blank" rel="noopener">Twitter</a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="d-flex">
              <v-list-item-icon>
                <v-icon class="color3">mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <a class="link" href="mailto:dao@inputlock.com">dao@inputlock.com</a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="d-flex">
              <v-list-item-icon>
                <v-icon class="color3">mdi-github</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <a class="link" href="https://github.com/inputlock" target="_blank" rel="noopener">GitHub</a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      iconSrc: require('@/assets/logo.png'),
      items: [
        {
          id: 1,
          event: 'Hacker compromised Desktops',
          without: 'Even with multi-person authorization, it\'s plausible for hackers to manipulate just multiple system to simulate multi-person approval.',
          with: 'Inputlock\'s hardware-based signing makes it virtually impossible for unauthorized transactions, ensuring high security.'
        },
        {
          id: 2,
          event: 'Data Corruption by Virus',
          without: 'Identifying corrupted data from regular backups is a daunting and time-consuming task due to manual verification.',
          with: 'Inputlock\'s signatures facilitate early detection of corrupted data, guaranteeing the integrity of data created by your team.'
        },
        {
          id: 3,
          event: 'Notebook Theft',
          without: 'Without disk encryption, stolen notebooks pose a significant risk. Even 2FA, like Google Authenticator, might not offer full protection due to potential backup and trusted-device vulnerabilities.',
          with: 'With inputlock, the risk of damage from a stolen notebook is significantly minimized, offering peace of mind, as they wont be able to corrupt data or execute critical transactions.'
        }
      ]
    };
  }
};
</script>

<style scoped>
/* CSS to remove the underline by default */
.link {
  color: rgb(var(--v-theme-primary));
  text-decoration: none;
}

/* CSS to add underline on hover */
.link:hover {
  text-decoration: underline;
}

.primary-color {
  color: rgb(var(--v-theme-primary));
}
</style>