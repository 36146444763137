<template>
  <v-app>
    <v-app-bar app dense dark color="white">
      <v-img :src="logoSrc"
          contain
          width="190px"
          class="px-0 v-col-auto"/>
      <v-spacer></v-spacer>
      <v-btn @click="setTab('home')" :class="tabClass('home')" class="ml-3">Home</v-btn>
      <v-btn @click="setTab('features')" :class="tabClass('features')" class="ml-3">Features</v-btn>
      <v-btn @click="setTab('how')" :class="tabClass('how')" class="ml-3">How it Works</v-btn>
      <v-btn @click="setTab('sdk')" :class="tabClass('sdk')" class="ml-3">SDK</v-btn>
      <v-btn @click="setTab('token')" :class="tabClass('token')" class="ml-3">Token</v-btn>
      <v-btn @click="setTab('dao')" :class="tabClass('dao')">DAO</v-btn>

      <v-btn href="https://dapp.inputlock.com/" target="_blank" flat class="ml-3 bg-primary text-white">Launch Beta!</v-btn>
    </v-app-bar>


    <v-main>
      <router-view/>
    </v-main>

    <v-footer dark padless>
      <v-col class="text-center white--text" cols="12">
        ©2024 Inputlock. All rights reserved.
      </v-col>
    </v-footer>
  </v-app>


</template>

<script>

import { useRouter } from 'vue-router';


export default {
  name: 'App',
  components: {

  },
  mounted() {
    document.title = "inputlock";
  },
  setup () {
    const router = useRouter(); // Correctly getting the router instance
    const goToPage = (page) => {
      router.push('/' + page); // Use router instance to navigate
    };
    return { goToPage };
  },
  watch: {
    // Watch for changes to the route
    '$route'(to) {
      this.tab = to.name;
    }
  },
  data () {
    return {
      tab: 'home',
      logoSrc: require('@/assets/logo-with-text.png'),
    }
  },
  methods: {
    setTab (tabName) {
      this.goToPage(tabName)
    },
    tabClass(tab) {
      return this.tab === tab ? 'text-primary' : 'text-black'; // Dynamically set the class based on the current tab
    },
  }
}
</script>

<style>
#app {
  font-family: 'Roboto Rounded', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;

}

.button-text-no-caps .v-btn__content {
  text-transform: none !important;
}

.color1 {
  color: #71CC32 !important;
}
.color2 {
  color: #4DD3F3 !important;
}
.color3 {
  color: #156AEA !important;
}
</style>
